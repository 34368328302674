.form {
  width: 430px;
  height: 100%;
  padding: 1rem 4rem 5rem 4rem;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff);
  display: flex;
  border: 1px solid #d9d9d9;
  align-items: center;
  flex-direction: column;
  border-radius: 1rem;
}

.map_sec {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
}

.title {
  color: wheat;
  margin: 3rem 0;
  font-size: 2rem;
}

.input {
  margin: 0.5rem 0;
  padding: 1rem 0.5rem;
  width: 21rem;
  background-color: inherit;
  color: #4e4e4e;
  border: none;
  outline: none;
}

.invalid {
  border-bottom: 1px solid #d32d48 !important;
}

.username {
  border-bottom: 1px solid #d9d9d9;
  transition: all 400ms;
  background-color: transparent !important;
}

.username:hover {
  background-color: #fff;
  /* border: 1px solid #d32d48; */
  border-radius: 0rem;
}

.password {
  border-bottom: 1px solid #d9d9d9;
  transition: all 400ms;
  background-color: transparent !important;
}

.password:hover {
  background-color: #fff;
  /* border: 1px solid #d32d48; */
  border-radius: 0rem;
}

.password_sec {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.password_toggle_button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
}
.password_toggle_buttonNew {
  background: none;
  border: 0px;
  position: absolute;
  top: 5px;
  right: 15px;
}

.btn {
  height: 3rem;
  width: 21rem;
  margin-top: 3rem;
  color: #fff;
  background-color: #282828;
  border-radius: 0.5rem;
  border: none;
  font-size: 1.2rem;
  transition: all 400ms;
  box-shadow: 0 0 5px antiquewhite, 0 0 5px antiquewhite;
}

.btn:hover {
  background-color: #d32d48;
  box-shadow: none;
}

.login_left_text {
  font-size: 5em;
  color: #ffffff;
  font-weight: 800;
  line-height: 48px;
  opacity: 0.8;
}

.login_left_text_second {
  font-size: 5em;
  color: #ffffff;
  font-weight: 500;
  line-height: 48px;
  font-family: cursive;
  font-style: italic;
  opacity: 0.8;
}

.login_logo {
  margin: 1rem;
  height: 100px;
  width: 100px;
}

.error_text {
  width: 100%;
  font-size: 14px;
  padding-left: 0.5rem;
}

@media (min-width: 1300px) and (max-width: 1450px) {
  .form {
    width: 350px;
    padding: 3rem 2rem 3rem 2rem;
  }
  .login_left_text,
  .login_left_text_second {
    font-size: 3em;
  }
  .login_logo {
    width: 65px;
  }
  .btn {
    width: 17rem;
  }
  .input {
    width: 17rem;
    padding: 0.5rem;
  }
  .error_text {
    padding-left: 0.3rem;
  }
  .btn {
    height: 2.7rem;
    margin-top: 2rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 1000px) {
  .form {
    width: 100%;
    padding: 1rem 2rem 3rem 2rem;
  }
  .map_sec {
    display: none;
  }
  .input,
  .btn {
    width: 17rem;
  }
  .error_text {
    padding-left: 0.5rem;
  }
  .title {
    margin: 1.5rem 0;
  }
  .login_logo {
    width: 70px;
  }
}
