/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

* {
  margin: 0;
  padding: 0;
  font-family: "Outfit";
}

body {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  background-color: #f3f3f3 !important;
  overflow: auto !important;
}

.css-1v5u2yv {
  padding: 0.5rem 0rem 0rem 0rem !important;
  padding-top: 0 !important;
  position: relative;
}

.css-zxtyt4-MuiPaper-root-MuiAppBar-root {
  background-color: #210720 !important;
  /* height: 76px; */
}

.ck-editor {
  color: black;
}

.col_custom {
  background-color: #f3f3f3;
}

.main_box_dashboard {
  display: none !important;
}

.table-responsive {
  overflow-x: clip !important;
}

.table_middle_text_box {
  -webkit-transform: translate(0px, 15px);
  -ms-transform: translate(0px, 15px);
  transform: translate(0px, 15px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.seo-note-box {
  margin-top: 1rem;
}

.seo-note-box .seo-note-title {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  font-weight: 500;
}

.seo-note-box .seo-note-title span {
  font-weight: 600;
}

.h_pop_up_img_box {
  height: 300px;
  width: 100%;
  margin-top: 2rem;
}

.h_pop_up_img_box img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.home_pop_up_form_g {
  border: 1px solid #d9d9d973;
  padding: 1rem;
}

.h_pop_up_main_title {
  font-size: 17px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.blog_desc .ck.ck-editor {
  position: relative;
  width: 100% !important;
}

.NoteBox {
  margin-top: 1.5rem;
  text-align: center;
}

.NoteBox p {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.NoteBox p span {
  color: red;
}

.table-product-name {
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: 30vw;
  overflow: hidden;
  text-align: start;
}

.table-input {
  width: 10vw !important;
}

.notification-box {
  position: relative;
  cursor: pointer;
}

.notification-box span {
  top: -2px;
  right: -3px;
  position: absolute;
  color: #fff;
  background-color: red;
  height: 15px;
  width: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  font-size: 10px;
}


@media (max-width: 768px) {
  .css-1v5u2yv {
    overflow-x: hidden;
  }

  .table-responsive {
    overflow-x: scroll !important;
  }

  .main_table_head th {
    text-wrap: nowrap;
  }

  .main_table_head th img {
    margin: 0px 5px;
  }
}